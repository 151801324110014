import { useEffect, useState } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { SplitClient, SplitTreatments } from '@splitsoftware/splitio-react'
import { TreatmentsWithConfig } from '@splitsoftware/splitio/types/splitio'
import Cookies from 'js-cookie'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import Turnstile from 'react-turnstile'

import { getReferralSource } from '@/redux/app/selectors'
import { login, receiveLoginError, resetInvalidLoginAttempt } from '@/redux/auth/actions'
import { getWalledGarden } from '@/redux/cookies/selectors'
import { getLoginLoading } from '@/redux/loading/selectors'
import { breakpoint } from '@/theme'
import { Form } from '@components/form-inputs'
import { A11yHiddenLabel } from '@components/form-inputs/label'
import { track } from '@helpers/analytics'
import { SPLIT_EXPERIMENTS, SPLIT_TREATMENTS, TURNSTILE_SITE_KEY } from '@helpers/constants'
import errorHandler from '@helpers/error-handler'
import ZendeskChat from '@helpers/zendesk-chat'
import { Button } from '@stories/buttons/button'
import { Email } from '@stories/inputs/email'
import { ShowPassword } from '@stories/inputs/password'
import PageContainer from '@stories/microcomponents/page/container'
import PageContentSmall from '@stories/microcomponents/page/content-small'
import ROUTES from 'helpers/routes'

import { TEST_IDS } from './test/constants'

const UNLIKELY_HUMAN_MESSAGE = `We could not verify that you're a human. Refresh this page and try again.`

export const defaultSplitConfig = {
  appearance: 'always'
}

export function getConfigAndStatus(treatments: TreatmentsWithConfig) {
  const treatmentIsOn =
    treatments[SPLIT_EXPERIMENTS.CLOUDFLARE_TURNSTILE].treatment === SPLIT_TREATMENTS.CLOUDFLARE_TURNSTILE.ON_ALWAYS ||
    treatments[SPLIT_EXPERIMENTS.CLOUDFLARE_TURNSTILE].treatment ===
      SPLIT_TREATMENTS.CLOUDFLARE_TURNSTILE.ON_INTERACTIVE

  let config = defaultSplitConfig
  try {
    config = JSON.parse(treatments[SPLIT_EXPERIMENTS.CLOUDFLARE_TURNSTILE].config)
  } catch (err) {
    // keep the default value if parsing fails
    errorHandler(
      new Error(
        `Failed to parse Split config for ${SPLIT_EXPERIMENTS.CLOUDFLARE_TURNSTILE}: ${JSON.stringify(
          treatments[SPLIT_EXPERIMENTS.CLOUDFLARE_TURNSTILE]
        )}`
      )
    )
    config = defaultSplitConfig
  }
  if (!config?.appearance) {
    // config will be null when treatment is 'control' or 'off'
    if (treatmentIsOn) {
      errorHandler(
        new Error(
          `Improper Split config for ${
            SPLIT_EXPERIMENTS.CLOUDFLARE_TURNSTILE
          } - (missing appearance value): ${JSON.stringify(treatments[SPLIT_EXPERIMENTS.CLOUDFLARE_TURNSTILE])}`
        )
      )
    }
    config = defaultSplitConfig
  } else if (!['always', 'execute', 'interaction-only'].includes(config.appearance)) {
    errorHandler(
      new Error(
        `Improper Split config for ${
          SPLIT_EXPERIMENTS.CLOUDFLARE_TURNSTILE
        } - (incorrect appearance value): ${JSON.stringify(treatments[SPLIT_EXPERIMENTS.CLOUDFLARE_TURNSTILE])}`
      )
    )
    config = defaultSplitConfig
  }

  return {
    config,
    treatmentIsOn
  }
}

export default function Login() {
  const router = useRouter()
  const dispatch = useDispatch()
  const isWalledOff = useSelector(getWalledGarden)
  const loading = useSelector(getLoginLoading)
  const [formDisabled, setFormDisabled] = useState(false)
  const [hasTurnstile, setTurnstile] = useState(false) // only used to manage location of star bg img
  const cookieAnonId = Cookies.get('ajs_anonymous_id')
  const referralSource = useSelector(getReferralSource)

  let anonymousId: string
  try {
    anonymousId = cookieAnonId && JSON.parse(cookieAnonId)
  } catch (error) {
    anonymousId = cookieAnonId || ''
  }

  const handleLoginClick = (inputs) => {
    const { redirectUrl } = router.query

    const redirect = Array.isArray(redirectUrl) ? redirectUrl[0] : redirectUrl

    dispatch(login(inputs.email.value, inputs.password.value, redirect as '/menu', referralSource))
  }

  const handleTurnstileError = (error: Error | any) => {
    /* Possible errors:
      "Failed to load Turnstile." - generated by CSP block
      "challenge_failed" - test sitekey set to always blocks
     */
    errorHandler(new Error(error.message))
  }

  const verifyTurnstileToken = (token: string) => {
    fetch('/api/v2/verify/turnstile', {
      method: 'POST',
      body: JSON.stringify({ token }),
      headers: {
        'content-type': 'application/json'
      }
    })
      .then((response) => {
        if (!response.ok) {
          dispatch(receiveLoginError({ message: UNLIKELY_HUMAN_MESSAGE }))
        }
        return response.json()
      })
      .then((data) => {
        setFormDisabled(!data.success)
      })
  }

  useEffect(() => {
    return () => {
      dispatch(resetInvalidLoginAttempt())
    }
  }, [dispatch])

  useEffect(() => {
    track('Login.View', { source: referralSource })
  }, [])

  return (
    <PageContainer
      containerStyle={hasTurnstile ? starBackgroundWithTurnstile : starBackground}
      navbarProps={{
        hideAddress: true,
        noCheckout: true,
        showSupportButton: !isWalledOff,
        hideSidebarButton: isWalledOff,
        unlinkLogo: isWalledOff
      }}>
      <PageContentSmall data-e2eid={TEST_IDS.PAGE}>
        <FormTitle>Log In</FormTitle>
        <FormContainer>
          <Form onSubmit={handleLoginClick} loading={loading} includeButton={false}>
            <A11yHiddenLabel htmlFor="email">Email</A11yHiddenLabel>
            <InputContainer>
              <Email
                id="email"
                name="email"
                placeholder="Email"
                eagerValidate
                required
                data-e2eid={TEST_IDS.USERNAME_INPUT}
              />
            </InputContainer>

            <A11yHiddenLabel htmlFor="password">Password</A11yHiddenLabel>
            <InputContainer>
              <ShowPassword
                placeholder="Password"
                id="password"
                name="password"
                eagerValidate
                required
                data-e2eid={TEST_IDS.PASSWORD_INPUT}
              />
            </InputContainer>
            <StyledLink>
              <Link href={ROUTES.RESET_PASSWORD}>
                <a>Forgot your password?</a>
              </Link>
            </StyledLink>
            <StyledButton
              type="submit"
              data-e2eid={TEST_IDS.SUBMIT}
              disabled={loading || formDisabled}
              loading={loading}>
              Log In
            </StyledButton>
          </Form>
          <BottomSection>
            {`Don't have an account?`}
            <Link data-e2eid={TEST_IDS.SIGNUP_BUTTON} href={ROUTES.SIGNUP}>
              <a>Sign up</a>
            </Link>
          </BottomSection>
        </FormContainer>
      </PageContentSmall>
      {anonymousId && (
        <SplitClient splitKey={anonymousId}>
          <SplitTreatments names={[SPLIT_EXPERIMENTS.CLOUDFLARE_TURNSTILE]}>
            {({ isReady, treatments }) => {
              const { config, treatmentIsOn } = getConfigAndStatus(treatments)

              const showTurnstile = isReady && treatmentIsOn
              setTurnstile(showTurnstile)

              return (
                showTurnstile && (
                  <Turnstile
                    sitekey={TURNSTILE_SITE_KEY}
                    theme="light"
                    appearance={config.appearance as 'always' | 'execute' | 'interaction-only'}
                    style={{ margin: '0 auto', textAlign: 'center' }}
                    onVerify={verifyTurnstileToken}
                    onLoad={() => setFormDisabled(true)}
                    onError={handleTurnstileError}
                  />
                )
              )
            }}
          </SplitTreatments>
        </SplitClient>
      )}
      <ZendeskChat />
    </PageContainer>
  )
}

const starImgWidth = 180
const contentWidth = breakpoint.min.sm

const starBackground = css`
  background-image: url(/static/images/star-2.svg);
  background-repeat: no-repeat;
  background-position: calc(100% + ${starImgWidth / 2}px) calc(100% - ${starImgWidth / 4}px);

  @media (min-width: ${breakpoint.min.sm}px) {
    background-position: calc(50% + ${contentWidth / 2 + starImgWidth / 5}px) calc(100% - ${starImgWidth / 2.25}px);
  }
`

const starBackgroundWithTurnstile = css`
  ${starBackground};

  background-position: calc(100% + ${starImgWidth / 2}px) calc(100% - ${starImgWidth / 1.5}px);

  @media (min-width: ${breakpoint.min.sm}px) {
    background-position: calc(50% + ${contentWidth / 2 + starImgWidth / 5}px) calc(100% - ${starImgWidth / 1.25}px);
  }
`

const StyledButton = styled(Button)`
  margin-top: ${(props) => props.theme.spacing.large}px;
  position: relative;
  width: 100%;
  max-width: 100%;
  z-index: 1;
`

const FormTitle = styled.h2`
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.typography.font.npBold};
  font-size: ${(props) => props.theme.typography.size.desktop.title3}px;
  margin-bottom: ${(props) => props.theme.spacing.xxl}px;

  &:first-of-type {
    margin-bottom: ${(props) => props.theme.spacing.xl}px;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledLink = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: ${(props) => props.theme.spacing.large}px;
`

const BottomSection = styled.div`
  margin-top: ${(props) => props.theme.spacing.larger}px;
  a {
    margin-left: 10px;
  }
`

const InputContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing.medium}px;
`
